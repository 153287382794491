<template>
  <div>
    <b-row v-if="trainings && trainings.length > 1">
      <b-col>
        <div>
          <div v-for="(training, index) in trainings" :key="index + '1'">
            <b-card>
              <template title="Title">
                <template>
                  <div class="d-flex justify-content-between align-items-center mb-2" style="gap: 20px">
                    <h3 class="study-timeline__text font-bold">
                      {{ training.tr_heading }}
                    </h3>
                    <h6 class="study-timeline__text font-bold">
                      {{ formatDate(training.tr_date) }}
                    </h6>
                  </div>
                </template>
                <b-tabs pills card>
                  <b-tab title="Description" active class="material-tab">
                    <div v-html="training.tr_description"></div>
                    <div>
                      Training Link
                      <a :href="training.tr_link" target="_blank">{{
                        training.tr_link
                      }}</a>
                    </div>
                    <b-button variant="outline-primary" :disabled="training.tr_reg_id"
                      @click="registerUser(training.tr_id)">
                      {{ training.tr_reg_id ? "Registered" : "Register" }}
                    </b-button>
                  </b-tab>
                  <b-tab class="material-tab" title="Material" @click="getSingleMaterial(training.tr_id, index)">
                    <div v-for="(material, mIndex) in training.materials" :key="mIndex" :title="material.name">
                      <h2>#{{ mIndex + 1 }} : {{ material.name }}</h2>

                      <template v-if="material.youtube_link">
                        YouTube Link: <br />
                        <div v-html="material.youtube_link"></div>
                      </template>
                      <template v-else-if="material.uploaded_file">
                        Uploaded File: <br>
                        <a :href="$FILESURL + material.uploaded_file">
                          <embed :src="$FILESURL + material.uploaded_file" width="200px" height="200px" />
                        </a>
                      </template>
                      <template v-else-if="material.file_link">
                        File Link:
                        <a :href="material.file_link">Download here</a>
                      </template>

                      <hr />
                    </div>
                  </b-tab>
                </b-tabs>
              </template>
            </b-card>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-else>
      <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
        <h3>No Training's Available</h3>
      </b-card>
    </div>
   
    <!-- </b-card> -->
  </div>
</template>


<script>
import { BCard, BCol, BRow, BButton, BTabs, BTab } from "bootstrap-vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import moment from "moment";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import CommonServices from "@/apiServices/CommonServices";
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    FeatherIcon,
    BTabs,
    BTab,
  },
  data() {
    return {
      moment,
      trainings: [],
    };
  },
  methods: {
    async getAllTrainings() {
      try {
        const res = await CommonServices.getAllTrainings();
        this.trainings = res.data.data.map(training => ({
          ...training,
          materials: [] // Initialize the materials property
        }));
      } catch (error) {
        console.error(`Error in getAllTrainings `, error);
      }
    },
    async getSingleMaterial(id, index) {
      try {
        const res = await CommonServices.getTrainingMaterial(id);
        this.$set(this.trainings[index], 'materials', res.data.data);
      } catch (error) {
        console.error(`Error in getSingleMaterial `, error);
      }
    },
    async registerUser(tr_id) {
      const payload = {
        tr_id,
      };
      const response = await CommonServices.registerUser(payload);
      if (response.data.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Registered",
            icon: "Check",
            variant: "success",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "User Already Registered",
            icon: "X",
            variant: "failure",
          },
        });
      }
      this.getAllTrainings();
    },

    formatDate(dateString) {
      return moment(dateString).format("D MMMM YYYY");
    },
  },

  beforeMount() {
    this.getAllTrainings();
  },
};
</script>
<style>
.material-tab div,
.material-tab p {
  margin-bottom: 20px !important;
}

.tabs {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  padding: 1rem;
}
</style>
